<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <tchtree v-model="searchForm.class_id" style="margin: 0 10px 10px 0;"></tchtree>
        <el-input placeholder="学号" v-model="searchForm.stu_num" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
        <el-input placeholder="姓名" v-model="searchForm.stu_name" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
        <el-input placeholder="联系电话" v-model="searchForm.tel" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>

        <el-select v-model="searchForm.job_status" clearable placeholder="求职状态" size="small"
          style="width:130px;margin: 0 10px 10px 0;">
          <el-option label="不限求职状态" value></el-option>
          <el-option :label="item.param_desc" v-for="(item, idx) in qzztArr" :key="idx"
            :value="item.param_value"></el-option>
        </el-select>
        <el-select v-model="searchForm.sex" clearable placeholder="性别" size="small"
          style="width:130px;margin: 0 10px 10px 0;">
          <el-option label="不限性别" value></el-option>
          <el-option label="女" :value="2"></el-option>
          <el-option label="男" :value="1"></el-option>
        </el-select>


        <el-date-picker style="width:130px;margin: 0 10px 10px 0;" v-model="searchForm.year" size="small" type="year"
          value-format="yyyy" placeholder="毕业年份"></el-date-picker>

        <el-button icon="el-icon-search" style="margin: 0 10px 10px 0;" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left: 10px;" size="small"
          type="primary" @click="tongbuJava">迁移老版本</el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
      style="width:fit-content;width:100%;">
      <el-table-column label="学号" prop="stu_num" show-overflow-tooltip width="135" align="center"></el-table-column>
      <el-table-column label="姓名" prop="stu_name" width="135" align="center"></el-table-column>
      <el-table-column label="性别" prop="sex" width="80px" align="center">
        <template slot-scope="scope">{{ scope.row.sex == 1 ? "男" : scope.row.sex == 2 ? "女" : "" }}</template>
      </el-table-column>
      <!-- <el-table-column label="毕业学校" prop="school_name" show-overflow-tooltip width="150px"
        align="center"></el-table-column> -->
      <el-table-column label="出生日期" prop="birthday" width="135" align="center"></el-table-column>

      <el-table-column label="年级" prop="nj_name" width="150px" align="center"></el-table-column>
      <el-table-column label="院系" prop="yx_name" width="150px" align="center"></el-table-column>
      <el-table-column label="专业" prop="profession_name" show-overflow-tooltip width="100px"
        align="center"></el-table-column>
      <el-table-column label="班级" prop="bj_name" width="150pd" align="center"></el-table-column>
      <el-table-column label="毕业时间" prop="leave_time" min-width="135" align="center"></el-table-column>
      <el-table-column label="联系电话" prop="tel" width="135" align="center"></el-table-column>
      <el-table-column label="求职状态" prop="job_status" width="100" align="center">
        <template slot-scope="scope">{{ qzzt[scope.row.job_status] || "-" }}</template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="small">简历详情</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" :destroy-on-close="true"
      custom-class="cus_dialog cus_dialogMax" width="1000px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;"><span style="font-weight: 700">{{ formData.stu_name }}</span> 简历详情</span>
      </div>
      <div class="rowContainer">
        <!--                头像-->
        <div class="item-userinfo flexStart">
          <div class="avatarContainer flexCenter">
            <el-image fit="cover" style="width:100px;height:100px;border-radius: 50%;"
              :src="formData.photo ? formData.photo : url"
              :preview-src-list="[formData.photo ? formData.photo : url]"></el-image>
          </div>
          <div class="right rightRow1 flex_1">
            <div class="top flexBetweenBottom">
              <div class="flexLeft">
                <div style="vertical-align: bottom">
                  <span class="userName">{{ formData.stu_name || '-' }}</span>
                  <img v-if="formData.sex == 1" style="width:14px;height:14px;" src="../../../../public/img/male.png"
                    alt="">
                  <img v-if="formData.sex == 2" style="width:14px;height:14px;" src="../../../../public/img/female.png"
                    alt="">
                </div>
                <div class="crumbInfo">
                  <i class="el-icon-message"></i>
                  <span style="margin-left: 10px">{{ formData.birthday || '-' }}</span>
                  <el-divider direction="vertical" />

                  <i class="el-icon-suitcase"></i>

                  <span style="margin-left: 10px">{{ xlyq[formData.eduaction] || '-' }} · {{
                    formData.profession_name ||
                    '-' }}</span>
                  <el-divider direction="vertical" />

                  <i class="el-icon-user"></i>

                  <!--                                    <span class="verticalBorder"></span>-->
                  <span style="margin-left: 10px">{{ qzzt[formData.job_status] || '-'
                    }}</span>
                </div>

              </div>

            </div>
            <div class="contact flexStart">

              <i class="el-icon-mobile-phone"></i>
              <span class="" style="margin-left: 10px">{{
                formData.tel
                }}</span>
              <el-divider direction="vertical" />

              <i class="el-icon-chat-round"></i>

              <span class="" style="margin-left: 10px">{{
                formData.wechat
                }}</span>
            </div>
            <div class="commonText">
              <span>个人优势：{{ formData.advandage }}</span>
            </div>
            <div v-if="formData.annexes && formData.annexes.length > 0 && formData.annexes[0]"
              class="annexResume flexStart">
              <span @click="openAnnexResume(formData.annexes[0].src)" class="overText" style="cursor:pointer;">
                <i class="el-icon-paperclip">简历附件</i>
              </span>
            </div>
          </div>
        </div>
        <!--                期望职位-->
        <div v-if="formData.job_array && formData.job_array.length > 0" class="item-userinfo flexStartUp">
          <div class="left">
            <div class="flexEnd">
              <span class="color60 fontWeight500">期望职位</span>
            </div>
          </div>
          <div class="right rightRow2 flex_1">
            <div v-for="(item, index) in formData.job_array" class="crumbInfo">
              {{ index + 1 }}.
              <span>{{ item.type_name || '-' }}</span>
              <el-divider direction="vertical" />

              <span>{{ item.cityName || '-' }}</span>
              <el-divider direction="vertical" />

              <!-- <span>{{ item.expectJob.industry || '-' }}</span> -->
              <!-- <el-divider direction="vertical" /> -->

              <span>{{ item.pay == 0 ? '面议' : (xzfw[item.pay] || '-') }}</span>
            </div>
          </div>
        </div>
        <!--                教育经历-->
        <div v-if="false" class="item-userinfo flexStartUp">
          <div class="left" :span="5">
            <div class="flexEnd">
              <span class="color30 fontWeight500">教育经历</span>
            </div>
          </div>
          <div class="right color30 flex_1">
            <div class="flexBetween">
              <div class="crumbInfo">
                <span>{{ formData.school_name || '-' }}学校</span>
                <el-divider direction="vertical" />

                <span>{{ formData.profession_name || '-' }}专业</span>
                <el-divider direction="vertical" />

                <span>{{ xlyq[formData.eduaction] || '-' }}学历</span>
              </div>
              <div class="timestamp">{{ formData.leave_time || '-' }}毕业时间</div>
            </div>
          </div>
        </div>
        <!--                实践经历-->
        <div class="item-userinfo" v-for="(item, index) in formData.practice" :key="index">
          <div class="item-userinfo flexStartUp">
            <div class="left" :span="5">
              <div class="flexEnd">
                <span class="color30 fontWeight500">实践经历</span>
              </div>
            </div>
            <div class="right color30 flex_1">
              <div class="flexBetween">
                <div class="crumbInfo">
                  <span>{{ item.enterName || '-' }}</span>
                  <el-divider direction="vertical" />

                  <span>{{ item.dept }} · {{ item.job_type_name || '-' }}</span>
                </div>
                <div class="timestamp">{{ item.startDate }}~{{ item.endDate }}</div>
              </div>
            </div>
          </div>
          <div class="item-userinfo subitem-userinfo flexStartUp">
            <div class="left" :span="5">
              <div class="flexEnd">
                <span class="color60">业绩</span>
              </div>
            </div>
            <div class="right color60 flex_1">
              <span>{{ item.jobContent || '-' }}</span>
            </div>
          </div>
          <div class="item-userinfo subitem-userinfo flexStartUp">
            <div class="left" :span="5">
              <div class="flexEnd">
                <span class="color60">内容</span>
              </div>
            </div>
            <div class="right color60 flex_1">
              <div>
                <span>{{ item.perfor || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--                项目经验-->
        <div class="item-userinfo" v-for="(item, index) in formData.project" :key="index + '1'">
          <div class="item-userinfo flexStartUp">
            <div class="left" :span="5">
              <div class="flexEnd">
                <span class="color30 fontWeight500">项目经验</span>
              </div>
            </div>
            <div class="right color30 flex_1">
              <div class="flexBetween">
                <div class="crumbInfo">
                  <span>{{ item.name || '-' }}</span>
                  <el-divider direction="vertical" />

                  <span>{{ item.role || '-' }}</span>
                </div>
                <div class="timestamp">{{ item.startDate }}~{{ item.endDate }}</div>
              </div>
            </div>
          </div>
          <div class="item-userinfo subitem-userinfo flexStartUp">
            <div class="left" :span="5">
              <div class="flexEnd">
                <span class="color60">业绩</span>
              </div>
            </div>
            <div class="right color60 flex_1">
              <span>{{ item.prefor || '-' }}</span>
            </div>
          </div>
          <div class="item-userinfo subitem-userinfo flexStartUp">
            <div class="left">
              <div class="flexEnd">
                <span class="color60">描述</span>
              </div>
            </div>
            <div class="right color60 flex_1">
              <div>
                <span>{{ item.projDesc || '-' }}</span>
              </div>
            </div>
          </div>
          <div v-if="item.annex && item.annex.length > 0" class="item-userinfo subitem-userinfo flexStartUp">
            <div class="left">
              <div class="flexEnd">
                <span class="color60">项目附件</span>
              </div>
            </div>
            <div class="right flex_1">
              <div class="flexStart" v-for="(att, indexAtt) in item.annex" :key="indexAtt">
                <span class="overText">{{ att.fileName }}</span>
                <span class="item-att" @click="openAnnexResume(att.fileUrl)">
                  <span>查看</span><i class="el-icon-arrow-right"></i>
                </span>
              </div>

            </div>
          </div>
        </div>
        <!--            获奖证书-->
        <div v-if="formData.award_annex && formData.award_annex.length > 0" class="item-userinfo">
          <div class="item-userinfo flexStartUp">
            <div class="left" :span="5">
              <div class="flexEnd">
                <span class="color30 fontWeight500">获奖证书</span>
              </div>
            </div>
            <div class="right color30 flex_1">
              <div class="crumbImg flex_1">

                <div class="thumbnail" v-for=" (item, index) in formData.award_annex" :key="index"
                  style="margin-right:10px;">
                  <el-image style="width: 100%; height: 100%" :src="item.fileUrl" :preview-src-list="[item.fileUrl]">
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-form :model="formData" label-width="100px" v-if="qzzt.length > 0">
        <el-row>
          <el-col :span="3" align="middle">
            <el-image style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList"></el-image>
          </el-col>
          <el-col :span="21">
            <el-row>
              <el-col :span="24">
                <div style="width: 100px; text-align: right; font-size: 20px;padding-bottom: 15px;font-weight: 900;">
                  <span style>{{ formData.stu_name }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="联系电话">: {{ formData.tel }}</el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="微信号">: {{ formData.wechat }}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="性别">: {{ formData.sex == 1 ? "男" : formData.sex == 2 ? "女" : "" }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="出生日期">: {{ formData.birthday }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="求职状态">: {{ qzzt[formData.job_status] || "-" }}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="学校">: {{ formData.school_name }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="专业">: {{ formData.profession }}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="毕业时间">: {{ formData.leave_time }}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="附件简历">: {{ formData.annex }}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="个人优势">: {{ formData.advandage }}</el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="期望职位">
              :
              <span v-html="jobHtml"></span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="实践经历">
              :
              <span v-html="practiceHtml"></span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="项目经验">
              :
              <span v-html="projectHtml"></span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-tabs type="border-card" style="margin-top: 10px">
            <el-tab-pane label="投递记录">
              <el-table v-tableFit :data="formData.resume_list" row-key="id" border stripe>
                <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                <el-table-column label="职位" prop="job_name" width="150px" align="center"></el-table-column>
                <el-table-column label="企业名称" prop="firm_name" width="150px" align="center"></el-table-column>
                <el-table-column label="学号" prop="stu_num" width="150px" align="center"></el-table-column>
                <el-table-column label="姓名" prop="stu_name" width="100px" align="center"></el-table-column>
                <el-table-column label="班级" prop="bj_name" min-width="100px" align="center"></el-table-column>
                <el-table-column label="年级" prop="nj_name" min-width="100px" align="center"></el-table-column>
                <el-table-column label="院系" prop="yx_name" min-width="100px" align="center"></el-table-column>
                <el-table-column label="投递时间" prop="create_date" width="200px" align="center"></el-table-column>
                <el-table-column label="投递状态" prop="status" width="100px" align="center">
                  <template slot-scope="scope">{{ tdzt[scope.row.status] || "-"
                    }}</template>
                </el-table-column>
                <el-table-column label="面试状态" prop="interview_status" width="100px" align="center">
                  <template slot-scope="scope">{{ mszt[scope.row.interview_status] || "-" }}</template>
                </el-table-column>
                <el-table-column label="录用状态" prop="hire_status" width="100px" align="center">
                  <template slot-scope="scope">{{ lyzt[scope.row.hire_status] || "-" }}</template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogForm = false" type="primary" plain>关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import tchtree from "../../com/tchTree.vue";
import citys from "../../../utils/arae_value.js";
import aesJs from '../../../utils/aes'
export default {
  components: {
    tchtree
  },
  data() {
    return {
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
      ],
      searchForm: {
        stu_num: "",
        stu_name: "",
        tel: "",
        sex: "",
        job_status: "",
        class_id: "",
        year: ""
      },
      tableData: [],
      dialogForm: false,
      formData: {},
      jobHtml: "",
      practiceHtml: "",
      projectHtml: "",
      qzzt: {}, qzztArr: [],
      tdzt: {},
      mszt: {},
      lyzt: {},
      xzfw: {},
      xlyq: {},

      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      }
    };
  },
  mounted() {
    this.getComType().then(rr => {
      this.getList();
    });
  },
  methods: {
    getComType() {
      return new Promise(resolve => {
        this.$http
          .post("/api/sys_param_list", {
            param_type: "'QZZT','TDZT','LYZT','MSZT','SALARY_RANGE','EDUACTION'",
            used: 0
          })
          .then(res => {
            let qzzt = {}, qzztArr = [] //求职状态
            let tdzt = {}; //投递状态
            let mszt = {}; //面试状态
            let lyzt = {}; //录用状态
            let xzfw = {}; //薪资类型
            let xlyq = {}; //学历类型

            for (let item of res.data) {
              if (item.param_type == "SALARY_RANGE") {
                xzfw[item.param_value] = item.param_desc;
              }
              if (item.param_type == "QZZT") {
                qzzt[item.param_value] = item.param_desc;
                qzztArr.push(item)
              }
              if (item.param_type == "TDZT") {
                tdzt[item.param_value] = item.param_desc;
              }
              if (item.param_type == "MSZT") {
                mszt[item.param_value] = item.param_desc;
              }
              if (item.param_type == "LYZT") {
                xzfw[item.param_value] = item.param_desc;
              }
              if (item.param_type == "EDUACTION") {

                xlyq[item.param_value] = item.param_desc;
              }
            }
            this.qzzt = qzzt; this.qzztArr = qzztArr
            this.tdzt = tdzt;
            this.mszt = mszt;
            this.lyzt = lyzt;
            this.xzfw = xzfw;
            this.xlyq = xlyq;

            resolve();
          });
      });
    },
    getList() {
      let data = {};
      data.stu_num = this.searchForm.stu_num;
      data.stu_name = this.searchForm.stu_name;
      data.tel = this.searchForm.tel;
      data.sex = this.searchForm.sex;
      
      if (this.searchForm.job_status || this.searchForm.job_status === 0 || this.searchForm.job_status === '0') {
        data.job_status = this.searchForm.job_status;
      }
      data.class_id = this.searchForm.class_id;
      data.year = this.searchForm.year;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      this.$http.post("/api/zp_resume_list", data).then(res => {
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    getParentId(list, iid) {
      if (list && list.length > 0) {
        for (let o of list) {
          if (o.value == iid) return o;
          const o_ = this.getParentId(o.children, iid);
          if (o_) return o_;
        }
      }
    },
    canParseToJson(str, parseFlag) {
      try {
        if (typeof JSON.parse(str) === "object" && ((Object.prototype.toString.call(JSON.parse(str)) === "[object Object]") ||
          (Object.prototype.toString.call(JSON.parse(str)) === "[object Array]"))) {
          return parseFlag === true ? JSON.parse(str) : true
        }
      } catch (e) { }
      return false
    },
    handleModify(row) {
      this.$http.post("/api/zp_resume_info", { id: row.id }).then(async res => {
        res.data = await aesJs.decrypt(res.data)
        res.data = JSON.parse(res.data)
        if (!res.data.tel) {
          res.data.tel = '***********'
        }

        if (!res.data.wechat) {
          res.data.wechat = '***********'
        }

        if (res.data.award_annex && this.canParseToJson(res.data.award_annex)) {
          res.data.award_annex = JSON.parse(res.data.award_annex)
        }
        if (res.data.job_array) {
          res.data.job_array.map(a => {
            let obj = this.getParentId(citys, a.city);
            a.cityName = obj ? obj.label : '';
          });
        }
        if (res.data.practice && this.canParseToJson(res.data.practice)) {
          res.data.practice = JSON.parse(res.data.practice)
        }
        if (res.data.project && this.canParseToJson(res.data.project)) {
          res.data.project = JSON.parse(res.data.project)
          res.data.project.map(a => {
            if (a.annex && this.canParseToJson(a.annex)) {
              a.annex = JSON.parse(a.annex)
            }
          })
        }

        this.formData = res.data;
        this.dialogForm = true;
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    openAnnexResume(url){
   
      console.log(611, url)
			let path = url
			const fileExtension = path.split('.').pop().toLowerCase()
      if (fileExtension === 'ppt' || fileExtension === 'pptx' || fileExtension === 'doc' || fileExtension === 'docx') {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(path))
			} else {
        // window.open('http://www.pfile.com.cn/api/profile/onlinePreview?url=' + encodeURIComponent(path));
        window.open(url, "_blank")
      }
      
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_resume", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    }
  }
};
</script>

<style scoped lang="less">
.cus_dialog {

  div {
    box-sizing: border-box;
  }

  .rowContainer {
    width: 100%;

    .item-userinfo {
      margin-top: 32px;

      .color30 {
        color: #303133;
      }

      .color60 {
        color: #606266;
      }

      .fontWeight500 {
        font-weight: 700;
      }

      .left {
        width: 100px;
      }

      .right {
        margin-left: 32px;

        .userName {
          font-size: 18px;
          color: #303133;
          font-weight: 500;
          margin-right: 4px;
        }

        .contact {
          margin-top: 8px;
        }

        .timestamp {
          color: #909399;
          font-size: 12px;
        }

        .commonText {
          margin-top: 8px;
        }

        .annexResume {
          margin-top: 8px;
          color: #3378fa;
          cursor: pointer;
        }

        .item-att {
          margin-left: 8px;
          color: #3378fa;
          cursor: pointer;
        }
      }
    }

    .item-userinfo:first-of-type {
      margin-top: 0;
    }

    .subitem-userinfo {
      margin-top: 16px;
      color: #606266;
    }

    .crumbImg {
      width: 100%;

      .thumbnail {
        display: inline-block;
        width: 20%;

        img {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }

  .avatarContainer {
    flex: 0 1 68px;

    &>span {
      border-radius: 68px;
      display: block;
      width: 68px;
      height: 68px;
      line-height: 68px;
    }

    .ant-avatar.ant-avatar-icon {
      font-size: 40px;
    }

    img {
      display: inline-block;
      width: 68px;
      height: 68px;
      border-radius: 50%;
    }
  }

  .right {
    .ant-divider-vertical {
      margin: 0 16px;
    }
  }
}
</style>